/* src/styles/globals.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: 0 0% 100%;
  --foreground: 222.2 47.4% 11.2%;
  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 222.2 47.4% 11.2%;
  --card: 0 0% 100%;
  --card-foreground: 222.2 47.4% 11.2%;
  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;
  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;
  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 210 40% 98%;
  --ring: 215 20.2% 65.1%;
  --radius: 0.5rem;

  --rdp-accent-color: theme('colors.primary.DEFAULT');
  --rdp-background-color: theme('colors.background');
  --rdp-accent-color-dark: theme('colors.primary.DEFAULT');
  --rdp-background-color-dark: theme('colors.background');
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  --rdp-accent-background-color: #f0f0f0;
  --rdp-day-font: 14px;
  --rdp-day-height: 40px;
  --rdp-day-width: 50px;
  --rdp-nav_button-height: 30px;
  --rdp-nav_button-width: 30px;
}

.dark {
  --background: 224 71% 4%;
  --foreground: 213 31% 91%;
  --muted: 223 47% 11%;
  --muted-foreground: 215.4 16.3% 56.9%;
  --popover: 224 71% 4%;
  --popover-foreground: 215 20.2% 65.1%;
  --card: 224 71% 4%;
  --card-foreground: 213 31% 91%;
  --border: 216 34% 17%;
  --input: 216 34% 17%;
  --primary: 210 40% 98%;
  --primary-foreground: 222.2 47.4% 1.2%;
  --secondary: 222.2 47.4% 11.2%;
  --secondary-foreground: 210 40% 98%;
  --accent: 216 34% 17%;
  --accent-foreground: 210 40% 98%;
  --destructive: 0 63% 31%;
  --destructive-foreground: 210 40% 98%;
  --ring: 216 34% 17%;
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
    font-family: 'Inter', sans-serif;
  }
}

/* RDP (React Date Picker) Styles */
.rdp-root .rdp-day, .rdp-root .rdp-nav_button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdp-root .rdp-nav_button {
  height: var(--rdp-nav_button-height);
  width: var(--rdp-nav_button-width);
}

.rdp-root .rdp-day {
  height: var(--rdp-day-height);
  width: var(--rdp-day-width);
  font-size: var(--rdp-day-font);
}

.rdp-nav_button {
  position: absolute !important;
  top: 0.5rem !important;
}

.rdp-nav_button_previous {
  left: 0.5rem !important;
}

.rdp-override .rdp-nav {
  position: relative;
  height: 2rem;
}

.rdp-override .rdp-nav_button {
  position: absolute !important;
  top: 0 !important;
}

.rdp-override .rdp-nav_button_previous {
  left: 0 !important;
}

.rdp-override .rdp-nav_button_next {
  right: 0 !important;
}

.rdp-override .rdp-caption {
  padding-top: 0;
}

/* Custom Classes */
.container {
  @apply flex flex-col items-center;
}

.button-container {
  @apply flex justify-center w-full mt-5;
}

.purple-button {
  @apply bg-purple-700 text-white border-none py-2 px-4 rounded cursor-pointer transition-colors duration-200;
}

.purple-button:hover {
  @apply bg-purple-800;
}

/* Modern UI Updates */
.button {
  @apply rounded-full transition duration-200 ease-in-out;
}

.button:hover {
  @apply transform scale-105;
}

.input {
  @apply rounded-lg transition duration-200 ease-in-out;
}

.input:focus {
  @apply transform scale-105;
}

.custom-select [data-value] {
  font-size: 0.75rem;
}